import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const stock_ichi1 = createIndicator({
    id: 'stockichi1',
    displayName: '主力防守區',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const donchian = (e) => {
                const t = this._context.new_var(this.PineJS.Std.low(this._context));
                const i = this._context.new_var(this.PineJS.Std.high(this._context));
                const o = this.PineJS.Std.avg(this.PineJS.Std.lowest(t, e, this._context), this.PineJS.Std.highest(i, e, this._context));
                return o;
            };
            const f_1 = () => {
                const donchianPeriod1 = 7; // this._input(0)
                const donchianPeriod2 = 29; // this._input(1)
                const donchianPeriod3 = 47; // this._input(2)
                const offset = 13; // this._input(3)
                // 計算 Donchian 通道
                // 7天高低點平均價
                const donchian1 = donchian(donchianPeriod1);
                // 29天高低點平均價
                const donchian2 = donchian(donchianPeriod2);
                // 7天與29天高低點平均價再取平均
                const avgDonchian = this.PineJS.Std.avg(donchian1, donchian2);
                // 47天高低點平均價
                const donchian3 = donchian(donchianPeriod3);
                // 取得收盤價
                const closePrice = this.PineJS.Std.close(this._context);
                // 判斷 avgDonchian 是否大於 donchian3
                const condition = this.PineJS.Std.gt(avgDonchian, donchian3) ? 0 : 1;
                return [
                    donchian1, // r
                    donchian2, // s
                    closePrice, // c
                    avgDonchian, // a
                    donchian3, // l
                    -offset, // -o
                    offset, // o
                    offset, // o
                    condition, // u
                ];
            };
            const i = f_1();
            return [
                i[0],
                i[1],
                {
                    value: i[2],
                    offset: i[5],
                },
                // avgDonchian和donchian3形成主力防守區
                {
                    value: i[3],
                    offset: i[6],
                },
                {
                    value: i[4],
                    offset: i[7],
                },
                i[8],
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 42,
        is_price_study: !0,
        is_hidden_study: !0,
        isCustomIndicator: true,
        scriptIdPart: '',
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 100,
                    color: '#0496ff',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 3,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 100,
                    color: '#0496ff',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 100,
                    color: '#459915',
                },
                plot_3: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 100,
                    color: '#008000',
                },
                plot_4: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 100,
                    color: '#FF0000',
                },
            },
            palettes: {
                palette_0: {
                    colors: {
                        0: {
                            color: '#f06292',
                            width: 1,
                            style: 0,
                        },
                        1: {
                            color: '#64b5f6',
                            width: 1,
                            style: 0,
                        },
                    },
                },
            },
            filledAreasStyle: {
                fill_0: {
                    color: '#000080',
                    transparency: 60,
                    visible: !0,
                },
            },
            inputs: {
            // in_0: 7,
            // in_1: 29,
            // in_2: 47,
            // in_3: 13,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: 'plot_3',
                type: 'line',
            },
            {
                id: 'plot_4',
                type: 'line',
            },
            {
                id: 'plot_5',
                palette: 'palette_0',
                target: 'fill_0',
                type: 'colorer',
            },
        ],
        styles: {
            plot_0: {
                title: 'Conversion Line',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'Base Line',
                histogramBase: 0,
                joinPoints: !1,
                isHidden: !1,
            },
            plot_2: {
                title: 'Lagging Span',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_3: {
                title: 'Lead 1',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_4: {
                title: 'Lead 2',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                },
                valToIndex: {
                    0: 0,
                    1: 1,
                },
            },
        },
        filledAreas: [
            {
                id: 'fill_0',
                objAId: 'plot_3',
                objBId: 'plot_4',
                type: 'plot_plot',
                title: '主力回防區',
                palette: 'palette_0',
            },
        ],
        inputs: [
        // {
        //   id: 'in_0',
        //   name: 'Conversion Line Periods',
        //   defval: 7,
        //   type: 'integer',
        //   min: 1,
        //   max: 1e12,
        // },
        // {
        //   id: 'in_1',
        //   name: 'Base Line Periods',
        //   defval: 29,
        //   type: 'integer',
        //   min: 1,
        //   max: 1e12,
        // },
        // {
        //   id: 'in_2',
        //   name: 'Lagging Span 2 Periods',
        //   defval: 47,
        //   type: 'integer',
        //   min: 1,
        //   max: 1e12,
        // },
        // {
        //   id: 'in_3',
        //   name: 'Displacement',
        //   defval: 13,
        //   type: 'integer',
        //   min: 1,
        //   max: 1e12,
        // },
        ],
    },
});
