import { errorEmptyArrayFetcher } from '~/modules/screener/containers/useStockScreenerResource';
import { apirc } from '~/configs/apirc';
import urlcat from 'urlcat';
import useSWR from 'swr';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { instrumentService } from '~/modules/SDK/services/instruments';
export const useStockNewsWallResource = (options) => {
    const { symbol } = options;
    const isStockFuturesSymbol = instrumentService.isTaiwanStockFutures(symbol);
    const isTaiwanStock = instrumentService.isTaiwanStockCode(symbol);
    // 非股期商品與台股股票不需要資料
    const url = !isStockFuturesSymbol && !isTaiwanStock
        ? null
        : urlcat(`${apirc.newsWall}`, '/api/misc/news', {
            query: `${symbol}+${fr_instrument.getName(symbol)}`,
        });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return res.data;
};
