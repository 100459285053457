import { css } from '@emotion/react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { store } from '~/pages/heineken_template/_private/store';
// import { StockNewsWall } from '~/modules/information-wall/stock-news-wall/StockNewsWall'
import { Good178S_StockNewsWall } from '~/pages/good178_stock/_private/Good178S_StockNewsWall';
import { fill_vertical_all_center, fill_horizontal_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
export const Good178S_Sidebar2 = memo(function Good178S_Sidebar2(props) {
    const symbolName = useSnapshot(store.charting).symbol;
    return (<div css={css `
        ${fill_vertical_all_center}
        padding: 4px;
      `}>
      <div css={css `
          ${fill_horizontal_all_center}
          height: 32px;
          font-size: 18px;
          font-weight: bold;
          border-radius: 6px;
          background-color: #333547;
          margin: 8px 0px;
        `}>
        <SymbolName symbol={symbolName}/>
        -熱門新聞牆
      </div>
      <Good178S_StockNewsWall symbol={symbolName}/>
    </div>);
});
